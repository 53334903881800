<template>
  <div class="billing-orders">
    <!--    <page-block-->
    <!--      :title="$t('title.inpay')"-->
    <!--      class="billing-orders__block billing-orders__block&#45;&#45;inpay"-->
    <!--    >-->
    <!--      <table-inpay-orders :columns="tableHead" @pay="onPay" />-->
    <!--    </page-block>-->
    <page-block :title="$t('title.item')" class="billing-orders__block billing-orders__block--item">
      <table-item-orders :columns="tableHead" />
    </page-block>
  </div>
</template>

<script>
import TableInpayOrders from '../components/TableInpayOrders.vue';
import TableItemOrders from '../components/TableItemOrders.vue';
import PaymentMethodConfigurator from '@/models/BillMgr/PaymentMethodConfigurator';
import wizard from '@/mixins/billmgr/wizard';
import handleRedirect from '@/mixins/billing/handleRedirect';
import updateBalance from '@/mixins/billing/updateBalance';
import showErrorModal from '@/mixins/showErrorModal';
export default {
  components: {
    // TableInpayOrders,
    TableItemOrders,
  },
  mixins: [wizard, handleRedirect, updateBalance, showErrorModal],
  data() {
    return {
      tableHead: [
        {
          key: 'date',
          label: this.$t('tableHead.date'),
          style: {
            width: '100px',
          },
          sort: {
            prop: 'createdate',
            order: 'asc',
          },
        },
        {
          key: 'title',
          label: this.$t('tableHead.title'),
          style: {
            width: '800px',
          },
          sort: {
            prop: 'title',
            order: 'asc',
          },
        },
        {
          key: 'cost',
          label: this.$t('tableHead.cost'),
          style: {
            width: '100px',
          },
          sort: {
            prop: 'cost',
            order: 'asc',
          },
        },
        {
          key: 'status',
          label: this.$t('tableHead.status'),
          style: {
            width: '100px',
          },
          sort: {
            prop: 'status',
            order: 'asc',
          },
        },
        /*{
          key: 'docs',
          label: this.$t('tableHead.docs'),
          style: {
            width: '100px',
          },
        },*/
      ],
      startFunc: 'payment.add',
      startParams: {},
      configuratorClass: PaymentMethodConfigurator,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.moduleBilling.moduleOrders.isLoading;
    },
    hidden() {
      return this.$store.getters['moduleBilling/moduleOrders/hiddenItems'];
    },
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    fetchList() {
      this.$store.dispatch('moduleBilling/moduleOrders/fetchList').catch(e => this.showError(e));
    },
    onPay(item) {
      // console.log(item);
      const billorder = item.id;
      this.startParams = { billorder };
      this.runWizardPay()
        .then(async data => {
          const res = await this.handleRedirect(data.ok);
          this.updateBalance();
        })
        .catch(() => {
          const basketItem = this.$store.state.moduleBasket.shadow[billorder]
            ? this.$store.state.moduleBasket.shadow[billorder][0]
            : null;
          if (basketItem) {
            this.$store.dispatch('moduleBasket/removeFromBasket', basketItem).finally(() => {
              this.$store.dispatch('moduleBasket/fetchBasket');
            });
          }
        })
        .finally(() => this.fetchList());
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": {
      "inpay": "Доступные для оплаты",
      "item": "Мои услуги"
    },
    "tableHead": {
      "date": "Дата",
      "title": "Название услуги",
      "cost": "Стоимость",
      "status": "Статус",
      "docs": "Документы"
    }
  }
}
</i18n>
